import * as ApiConfig from "../api/api-config";
import Cookies from "universal-cookie";
import { useLaunchParams,isTMA, retrieveLaunchParams } from '@telegram-apps/sdk-react';
import * as accountAction from "../redux/store/account/account.store";
import { GenerateDeviceId } from "./tools";
import { UrlCollectionSarang } from "../common/url-collection-sarang";
import { DomainAdminSide, TokenKey, getUserInfo, removeCookies, getCookies, setCookies, setCookiesUser } from '../utils/configuration';
export function login() {

    removeCookies("isShowDialog");
    removeCookies("isLockScreen");
    removeCookies(TokenKey.token);
    removeCookies(TokenKey.refreshToken);
    removeCookies(TokenKey.returnUrl);
    removeCookies("screenAllow");
    setCookies(TokenKey.returnUrl, window.location.href);
    if (isTMA('simple')) {
        const { initDataRaw } = retrieveLaunchParams();
        telegramLogin(initDataRaw);
        console.log(initDataRaw);
    }
    else
        window.location.replace(DomainAdminSide + "/dang-nhap");
}

const telegramLogin = (encodedUrl) => {
    let DeviceId = GenerateDeviceId();
    console.log('telegram login')
    accountAction
        .TelegramLogin({
            EncodedUrl: encodedUrl,
            DeviceId: DeviceId
        })
        .then(
            (res) => {
                if (res && res.content && res.content.token) {

                    setCookiesUser(res);
                    accountAction.GetScreenAllow()
                        .then(
                            (result) => {
                                if (result && result.content) {
                                    setCookies("screenAllow", result.content);
                                    //alert(result.content.length);
                                }
                            },
                            (err) => {
                                ;
                            }
                        );

                    removeCookies("isShowDialog");
                    removeCookies("isLockScreen");
                    window.location.replace(getCookies('returnUrl') ? getCookies('returnUrl') : DomainAdminSide + UrlCollectionSarang.Profile);
                }
            },
            (err) => {
                window.location.replace(DomainAdminSide + "/dang-nhap") ;
            }
        );
};
