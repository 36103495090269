import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetListCuisineOrder = (pageIndex = 1, pageSize = 10, sortExpression = "", searchData=undefined) => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    searchData?.orderFromDate && params.append("orderFromDate", searchData?.orderFromDate);
    searchData?.orderToDate && params.append("orderToDate", searchData?.orderToDate);
    searchData?.departmentId && params.append("departmentId", searchData?.departmentId);
    searchData?.herdsmanId && params.append("herdsmanId", searchData?.herdsmanId);
    searchData?.status && params.append("status", searchData?.status);
    searchData?.receiptTime && params.append("receiptTime", searchData?.receiptTime);
    searchData?.districtId && params.append("districtId", searchData?.districtId);
    searchData?.communeId && params.append("communeId", searchData?.communeId);
    searchData?.address && params.append("address", searchData?.address);
    searchData?.deliveryHerdsmanId && params.append("deliveryHerdsmanId", searchData?.deliveryHerdsmanId);
    sortExpression && params.append("sorting", sortExpression); 
    return service.get(ApiUrl.GetListCuisineOrder, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetListCuisineOrderByShipper = (pageIndex = 1, pageSize = 10, sortExpression = "", searchData = undefined) => {
    const params = new URLSearchParams();
    params.append("pageIndex", pageIndex);
    params.append("pageSize", pageSize);
    searchData?.orderFromDate && params.append("orderFromDate", searchData?.orderFromDate);
    searchData?.orderToDate && params.append("orderToDate", searchData?.orderToDate);
    searchData?.departmentId && params.append("departmentId", searchData?.departmentId);
    searchData?.herdsmanId && params.append("herdsmanId", searchData?.herdsmanId);
    searchData?.status && params.append("status", searchData?.status);
    searchData?.receiptTime && params.append("receiptTime", searchData?.receiptTime);
    searchData?.districtId && params.append("districtId", searchData?.districtId);
    searchData?.communeId && params.append("communeId", searchData?.communeId);
    searchData?.address && params.append("address", searchData?.address);
    searchData?.deliveryHerdsmanId && params.append("deliveryHerdsmanId", searchData?.deliveryHerdsmanId);
    sortExpression && params.append("sorting", sortExpression);
    return service.get(ApiUrl.GetListCuisineOrderByShipper, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetCuisineOrderSettings = () => {
    return service.get(ApiUrl.GetCuisineOrderSettings).then((res) => { return res }).catch((err) => { throw err });
}
export const GetDetailCuisineOrder = (id) => {
    return service.get(ApiUrl.GetDetailCuisineOrder.replace("{id}",id)).then(res => { return res }).catch((err) => { throw err });
}
export const CreateCuisineOrder = (data) => {
    return service.post(ApiUrl.CreateCuisineOrder, data).then((res) => { return res }).catch(err => { throw err });
}
export const UpdateCuisineOrder = (data) => {
    return service.put(ApiUrl.UpdateCuisineOrder, data).then((res) => { return res }).catch(err => { throw err });
}
export const DeleteCuisineOrder = (id) => {
    return service.delete(ApiUrl.DeleteCuisineOrder.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeletePermanentCuisineOrder = (id) => {
    return service.delete(ApiUrl.DeletePermanentCuisineOrder.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const ActiveCuisineOrder = (id) => {
    return service.put(ApiUrl.ActiveCuisineOrder.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const DeActiveCuisineOrder = (id) => {
    return service.put(ApiUrl.DeActiveCuisineOrder.replace("{id}",id)).then((res) => { return res }).catch(err => { throw err });
}
export const ChangeCuisineOrderStatus = (data) => {
    return service.put(ApiUrl.ChangeCuisineOrderStatus,data).then((res) => { return res }).catch(err => { throw err });
}
export const ChangeConfirm = (data) => {
    return service.put(ApiUrl.ChangeCuisineOrderConfirm,data).then((res) => { return res }).catch(err => { throw err });
}

export const ChangeDelivery = (data) => {
    return service.put(ApiUrl.ChangeCuisineOrderDelivery,data).then((res) => { return res }).catch(err => { throw err });
}

export const SumaryByDateCuisineOrder = (fromDate=undefined, toDate=undefined) => {
    const params = new URLSearchParams();
    fromDate && params.append("fromDate", fromDate);
    toDate && params.append("toDate", toDate);
    return service.get(ApiUrl.SumaryByDateCuisineOrder, params).then((res) => { return res }).catch((err) => { throw err });
}
export const CheckExistsCuisine = (herdsmanId, dateOrder) => {
    const params = new URLSearchParams();
    params.append("herdsmanId", herdsmanId);
    params.append("dateOrder", dateOrder);
    return service.get(ApiUrl.CheckExistsCuisine, params).then((res) => { return res }).catch((err) => { throw err });
}