import React, { useEffect, useState, useRef } from 'react';
import * as examinationAction from "../../../redux/store-sarang/examination-report/examination-report-management.store";
import * as departmentAction from "../../../redux/store-sarang/department/department-management.store";
import { NotificationMessageType } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../language/vi-VN.json";
import LoadingViewAria from '../../../components/layout-sarang/loading-view-aria'
import { optionsExamination } from '../../../common/constant';
export default function DepartmentExamReport(props) {
    const { loading} = props;

    const [loadingSwat, setLoadingSwat] = useState(loading);
    const [department, setDepartment] = useState();
    const [departmentReport, setDepartmentReport] = useState();
    const weeks = [1, 2, 3, 4, 5];
    const scrollRoot = useRef(null);
    const scrollTable = useRef(null);
    const [scrollTableWidth, setScrollTableWidth] = useState(100);
    const [totalItem, setTotalItem] = useState(0);
    useEffect(() => {
        getLookupDepartment();
        GetDepartmentExaminationReport();
    }, []);

    useEffect(() => {
        if (scrollTable.current) {
            setScrollTableWidth(scrollTable.current.clientWidth)
        }
    }, [totalItem+1]);

    const fetchData = async () => {
        setLoadingSwat(true);
        try {
            await Promise.allSettled([
                getLookupDepartment(),
            ]);
        } catch (err) {
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            setLoadingSwat(false);
        }
    }

    const getLookupDepartment = async () => {
        try {
            const res = await departmentAction.GetListNoPermiss(); //.GetLookupDepartment();
            if (res && res.content && res.content.items) {
                setDepartment(res.content.items.filter(x => x.id !== 53 && x.id !== 54));
            }
        } catch (err) {
            throw err;
        }
    }

    const GetDepartmentExaminationReport = async () => {
        setLoadingSwat(true);
        try {
            const res = await examinationAction.GetDepartmentExam();

            if (res && res.content) {
                setDepartmentReport(res.content);
            }

            setTotalItem(100);

        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoadingSwat(false);
        }
    };

    const renderData = (reportItem, parent) => {
        return <>
            <td style={{ fontWeight: parent ? 700 : 300, textAlign: 'right' }} >{reportItem?.quantity}</td>
            <td style={{ fontWeight: parent ? 700 : 300, textAlign: 'right' }} >{reportItem?.point}</td>
        </>
    }

    const sumPoint = (dptmId, week) => {
        let sumParentDptmPoint = 0;
        departmentReport && departmentReport.filter(x => x.departmentId == dptmId && x.week == week).map((item) => {
            sumParentDptmPoint = sumParentDptmPoint + item.point;
        });
        return sumParentDptmPoint;
    }
    const renderRow = (itemDptm, parent) => {
        let sumParentDptmPoint = 0;
        departmentReport && departmentReport.filter(x => x.departmentId == itemDptm.id && x.week == 1).map((item) => {
            sumParentDptmPoint = sumParentDptmPoint + item.point;
        });
        return <>
            <tr>
                <td style={parent ? { fontWeight: 700 } : {}}  >{itemDptm.name}</td>
                {weeks.map((itemWeek, index) => <>

                    {optionsExamination.map((itemOption) => <>
                        {/*
                                                                Lọc theo bộ phận
                                                                Lọc theo tuần
                                                                Lọc theo status
                                                                */}
                        {departmentReport && renderData(departmentReport.find(x => x.departmentId == itemDptm.id && x.week == itemWeek && x.status == itemOption.value), parent)}
                    </>)}
                    <td style={parent ? { fontWeight: 700, backgroundColor: 'rgba(0, 0, 0, .04)', textAlign: 'right' } : {backgroundColor: 'rgba(0, 0, 0, .04)', textAlign: 'right' }} >{Math.round(sumPoint(itemDptm.id, itemWeek) * 100) / 100}</td>
                </>)}
                {optionsExamination.map((itemOption) => <>
                    {/*
                                                                Lọc theo bộ phận
                                                                Lọc theo tuần
                                                                Lọc theo status
                                                                */}
                    {departmentReport && renderData(departmentReport.find(x => x.departmentId == itemDptm.id && x.week == 0 && x.status == itemOption.value), parent)}
                </>)}
                <td style={parent ? { fontWeight: 700, backgroundColor: 'rgba(0, 0, 0, .04)', textAlign: 'right' } : { backgroundColor: 'rgba(0, 0, 0, .04)', textAlign: 'right' }}  >{Math.round(sumPoint(itemDptm.id, 0) * 100) / 100}</td>
            </tr>
        </>
    }

    const renderDepartmentRow = (itemDptm) => {
        return <>
            {
                renderRow(itemDptm, true)
            }
            {
                department && department.length > 0 && department.filter(x => x.parentId == itemDptm.id).map((itemDptmChld) => renderRow(itemDptmChld, false))
            }
        </>
    }
    return (
        <div className="col-12">
            <div className="card">
                {/*<h5 className="card-header">báo cáo</h5>*/}
                <div className="card-body">
                    <div className="row">
                        <div className="dataTables freeze-column" ref={scrollRoot}>
                            <table className="table table-bordered table-hover dataTable" ref={scrollTable} id="datatable">
                                <thead>
                                    <tr>
                                        <th className="freeze" rowSpan={3} style={{minWidth: '50px' }}>Đội</th>
                                        {weeks.map((item, index) => <th colSpan={optionsExamination.length * 2 + 1}>Tuần {index + 1}</th>)}
                                        <th colSpan={optionsExamination.length * 2 + 1} style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle'}}>Tháng</th>
                                    </tr>
                                    <tr>
                                        {weeks.map((item, index) => <>
                                            {optionsExamination.map((item) => <th colSpan={2} style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle'}}>{item.label}</th>)}
                                            <th>Tổng</th>
                                        </>)}
                                        {optionsExamination.map((item) => <th colSpan={2} style={{ fontWeight: 'bold', textAlign: 'center', verticalAlign: 'middle'}}>{item.label}</th>)}
                                        <th>Tổng</th>
                                    </tr>
                                    <tr>
                                        {weeks.map((item, index) => <>
                                            {optionsExamination.map((item) => <>
                                                <th>SL</th>
                                                <th>Điểm</th>
                                            </>)}
                                            <th>Điểm</th>
                                        </>)}
                                        {optionsExamination.map((item) => <><th>SL</th>
                                            <th>Điểm</th></>)}
                                        <th>Điểm</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    {
                                        renderDepartmentRow({name:"Tổng", id:0})
                                    }
                                </tfoot>
                                <tbody>
                                    {
                                        department && department.length > 0 && department.filter(x => x.parentId == null || x.parentId === undefined).map((itemDptm) => renderDepartmentRow(itemDptm))
                                    }
                                </tbody>
                            </table>
                        </div>

                        <LoadingViewAria loading={loadingSwat} />
                    </div>
                    {
                        scrollTableWidth ?
                            <div className='scrollRoot'
                                onScroll={(e) => { scrollRoot.current.scrollLeft = e.target.scrollLeft }}
                            >
                                <div style={{ width: `${scrollTableWidth}px` }}>
                                    &nbsp;
                                </div>
                            </div> : <></>
                    }
                </div>
            </div>
            
        </div>
    )
}