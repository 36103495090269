import React, { useEffect, useState } from 'react';
import * as herdsmanAcademyManagementAction from "../../../redux/store-sarang/academy-practice/herdsman-academy-management.store";
import { NotificationMessageType, getFestivalCog, formatNumber } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../language/vi-VN.json";
import LoadingViewAria from '../../../components/layout-sarang/loading-view-aria'
import { optionsExamination } from '../../../common/constant';
import Popover from '@mui/material/Popover';

export default function HMAcademyDepartmentRankCupWidget(props) {
    const { userInfo, week, limit, currentFestival } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [loading, setLoading] = useState(false);
    const [listHerdsmanRank, setListHerdsmanRank] = useState([]);
    const [departmentData, setDepartmentData] = useState();

    useEffect(() => {
        GetAllListDepartmentRank()
    }, [week, currentFestival]);

    const GetAllListDepartmentRank = async () => {
        setLoading(true);
        try {
            if (!currentFestival) return;
            const res = await herdsmanAcademyManagementAction.GetListDepartmentRank(currentFestival?.id, limit, week?.week);

            if (res && res.content) {
                setListHerdsmanRank(res.content);
            }

        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoading(false);
        }
    };

    //const GetDepartmentExaminationReport = async (departmentId) => {
    //    setLoading(true);
    //    try {
    //        const res = await examinationAction.GetDepartmentExaminationReport(departmentId, currentFestival?.id, week?.week);

    //        if (res && res.content) {
    //            setDepartmentData(res.content);
    //        }

    //    } catch (err) {
    //        if (err && err.errorType) {
    //            ShowNotification(
    //                viVN.Errors[err.errorType],
    //                NotificationMessageType.Error
    //            );
    //        }
    //    } finally {
    //        setLoading(false);
    //    }
    //};

    //const dptmClick = (event, dptmId) => {
    //    setAnchorEl(event.currentTarget);
    //    GetDepartmentExaminationReport(dptmId);
    //};

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderPoint = (point) => {
        return <>{formatNumber(point * 100)}%</>
    }
    return (
        <>
            {
                //<Popover
                //    id='buoc1'
                //    open={open}
                //    anchorEl={anchorEl}
                //    onClose={handleClose}
                //    anchorOrigin={{
                //        vertical: 'bottom',
                //        horizontal: 'left',
                //    }}
                //>
                //    {/*<Typography sx={{ p: 2 }}>test</Typography>*/}
                //    {
                //        departmentData?.rP_Examination_Report_Dtos && departmentData?.rP_Examination_Report_Dtos.length > 0 &&
                //        <table className="table dataTable">
                //            <thead>
                //                <tr >
                //                    <td style={{ minWidth: "100px" }}><b>Nội dung</b></td>
                //                    <td style={{ minWidth: "50px" }}><b>#</b></td>
                //                    <td style={{ minWidth: "50px" }}><b>Điểm</b></td>
                //                </tr>
                //            </thead>
                //            <tbody>
                //                {
                //                    departmentData?.rP_Examination_Report_Dtos.map((item, index) =>
                //                        <tr>
                //                            <td>{optionsExamination.find(x => x.value == item.status)?.label}</td>
                //                            <td>{item.quantity}</td>
                //                            <td>{item.quantity * item.point}</td>
                //                        </tr>)
                //                }
                //            </tbody>
                //        </table>
                //    }
                //</Popover>
            }
            <div className="card">
                <h5 className="card-header" style={{ fontWeight: 700, color: '#68a1c3' }}>Top Địa vực</h5>
                <div className="card-body">
                    <div className="row text-center">
                        <div className="col-4">
                            {
                                listHerdsmanRank.length > 1 &&
                                <div style={{ cursor: 'pointer' }} onClick={(event) => {
                                    //dptmClick(event, listHerdsmanRank[1].departmentId);
                                }}>
                                    <img src="assets/images/rank/Second1.png" style={{width: "60px", paddingTop:"10px"}} />
                                        <div style={{ marginTop: "10px", fontWeight: 700 } }>{
                                            listHerdsmanRank[1].departmentName
                                        }</div>
                                    <div style={{ fontWeight: 900, color:"#0867c3" }}>{renderPoint(listHerdsmanRank[1].totalPoint)}<br /> &nbsp;</div>
                                </div>
                            }
                        </div>
                        <div className="col-4">
                            {
                                
                                listHerdsmanRank.length > 0 &&
                                <div style={{ cursor: 'pointer' }} onClick={(event) => {
                                    //dptmClick(event, listHerdsmanRank[0].departmentId);
                                }}>
                                    <img src="assets/images/rank/First1.png" style={{width: "70px"}}  />
                                    <div style={{marginTop: "10px", fontWeight:700} }>{
                                            listHerdsmanRank[0].departmentName
                                        }</div>
                                        
                                        <div style={{ fontWeight: 900, color: "#0867c3" }}>{renderPoint(listHerdsmanRank[0].totalPoint)}</div>
                                </div>
                            }
                        </div>
                        <div className="col-4">
                            {
                                listHerdsmanRank.length > 2 &&
                                <div style={{ cursor: 'pointer' }} onClick={(event) => {
                                    //dptmClick(event, listHerdsmanRank[3].departmentId);
                                }}>
                                    <img src="assets/images/rank/Third1.png" style={{width: "55px", paddingTop:"15px"}}  />
                                        <div style={{ marginTop: "10px", fontWeight: 700 } }>{
                                            listHerdsmanRank[2].departmentName
                                        }</div>
                                        <div style={{ fontWeight: 900, color: "#0867c3" }}>{renderPoint(listHerdsmanRank[2].totalPoint)}</div>
                                </div>
                            }
                        </div>
                        
                    </div>
                    <div className="row text-center">
                        <table className="table dataTable table-hover " id="datatable">
                            <thead>
                                <tr>
                                    <td style={{ minWidth: "50px" }}><b>Xếp Hạng</b></td>
                                    <td style={{ minWidth: "100px" }}><b>Địa vực</b></td>
                                    <td style={{ minWidth: "50px" }}><b>Điểm</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listHerdsmanRank.map((item, index) => 
                                        index > 2 && 
                                        <tr key={index }>
                                                <td>{item.rank}</td>
                                                <td style={{ cursor: 'pointer' }} onClick={(event) => {
                                                    //dptmClick(event, item.departmentId);
                                                }}>{item.departmentName}</td>
                                                <td>{renderPoint(item.totalPoint)}</td>
                                            </tr>
                                        )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <LoadingViewAria loading={loading} />
            </div>
        </>
    )
}