/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import viLocale from "date-fns/locale/vi";
import dateformat from "dateformat";
import Select from "react-select";

//--- Action
import * as userManagementAction from "../../../redux/store/user-management/user-management.store";

//--- Material Control
import {
  DialogActions,
  TextareaAutosize,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  makeStyles,
  Typography,
  IconButton,
  Select as SelectMui,
  MenuItem,
} from "@material-ui/core";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

//--- Material Icon
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";

//--- Notifications
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType, APIUrlDefault } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import * as planningUnitAction from "../../../redux/store/planning-unit/planning-unit.store";
import FileManagement from "../../../components/file_management/file_management";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function EditUserManagement(props) {
  const classes = useStyles();

    const {
        roleSelected,
        listRoles,
        isOpen,
        onClose,
        onSuccess,
        userId,
        setOrder,
        setOrderBy,
        GetListUserManagement,
        rowsPerPage,
        showLoading,
        herdsmans,
        departments,
        zionAreas,
        wmTeams,
        userSelected,
    } = props;

  const [userModel, setUserModel] = useState();
  const [avatar, setAvatar] = useState();
  const [sex, setSex] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [roleLockup, setRoleLockup] = useState();
  const [files, setFiles] = useState([]);
  const [filesTemp, setFilesTemp] = useState([]);
  const [isShow, setShow] = useState(false);
  //const [listReflectionProcessingUnit, setListReflectionProcessingUnit] = useState([]);
  //const [reflectionProcessingUnitSelected, setReflectionProcessingUnitSelected] = useState(null);
  //const [listPlanningUnit, setListPlanningUnit] = useState([]);
  //const [planningUnitSelected, setPlanningUnitSelected] = useState(null);
    const [selected, setSelected] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState();
    const [selectedHerdsman, setSelectedHerdsman] = useState();
    const [selectedZionArea, setSelectedZionArea] = useState();
    const [selectedWMTeam, setSelectedWMTeam] = useState();

  //const options = listRoles.map((item) => {return {value: item.id,label: item.name}});

  useEffect(() => {
    onGetAllData();
    //userManagementAction.GetDetailUserManagement(userId).then((res)=> {
    //    setSelected(res.content.roleIds);
      //})
  }, []);

    useEffect(() => {
        if (userSelected && userSelected.dataPermissions && userSelected.dataPermissions.length > 0) {
            const lstDept = userSelected.dataPermissions.find(item => item.dataType === "DEPARTMENT");
            if (lstDept)
                setSelectedDepartment(departments.filter(item => lstDept.value.includes(item.value)));
            else
                setSelectedDepartment();

            const lstHrds = userSelected.dataPermissions.find(item => item.dataType === "HERDSMAN");
            if (lstHrds)
                setSelectedHerdsman(herdsmans.filter(item => lstHrds.value.includes(item.value)));
            else
                setSelectedHerdsman();

            const lstZA = userSelected.dataPermissions.find(item => item.dataType === "ZIONAREA");
            if (lstZA)
                setSelectedZionArea(zionAreas.filter(item => lstZA.value.includes(item.value)));
            else
                setSelectedZionArea();

            const lstWM = userSelected.dataPermissions.find(item => item.dataType === "WMTEAM");
            if (lstWM)
                setSelectedWMTeam(wmTeams.filter(item => lstWM.value.includes(item.value)));
            else
                setSelectedWMTeam();
        }
        if (!userSelected || !userSelected.dataPermissions) {
            setSelectedDepartment();
            setSelectedHerdsman();
            setSelectedZionArea();
            setSelectedWMTeam();
        }

    }, [userSelected]);

    const onGetAllData = () => {
        showLoading(true);
        Promise.all([
            GetDetailUserManagement(userId),
            GetRoleLookupUserManagement(),
        ])
            .then((res) => {
                const [userModel, roleLockup] = res;
                setUserModel(userModel && userModel.content ? userModel.content : []);
                setSelected(userModel && userModel.content.roleIds);
                if (userModel && userModel.content) {
                    setSelectedDate(userModel.content.dateOfBirth);
                    setAvatar(userModel.content.avatar);
                    setRoleId(userModel.content.roleId);
                    setSex(userModel.content.sex);
                    setFiles(userModel.content.files ? [userModel.content.files] : [])
                }
                setRoleLockup(roleLockup.content.map((item) => {
                    return { ...item, label: item.name, value: item.id };
                }));
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    };

  const GetDetailUserManagement = (userId) => {
    return new Promise((resolve, reject) => {
      userManagementAction.GetDetailUserManagement(userId).then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const GetLookUpReflectionProcessingUnit = () => {
    return new Promise((resolve, reject) => {
      userManagementAction.GetLookUpReflectionProcessingUnit().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };
  const GetLookupPlanningUnit = () => {
    return new Promise((resolve, reject) => {
      planningUnitAction.GetLookupPlanningUnit().then(
        (res) => {
          resolve(res.content);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };
  const GetRoleLookupUserManagement = () => {
    return new Promise((resolve, reject) => {
      userManagementAction.GetRoleLookupUserManagement().then(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
          err &&
            err.errorType &&
            ShowNotification(
              viVN.Errors[err.errorType],
              NotificationMessageType.Error
            );
        }
      );
    });
  };

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const onSubmit = (data) => {
    if (!data) {
      return;
      }
      console.log(selected);
    let formData = new FormData();
    formData.append("Id", userId);
    formData.append("FullName", data.fullName);
      formData.append("Email", userModel.email);
    //formData.append("RoleId", roleId);
    selected.map((item) => {
      
      formData.append("Roles", item.value);  
    })
    formData.append("DateOfBirth", selectedDate);
    formData.append("Sex", sex);
    formData.append("Address", data.address);
    userModel.documentUploadId && formData.append("DocumentUploadId", userModel.documentUploadId)
    formData.append("Description", data.content ? data.content : "");
    formData.append("PhoneNumber", data.phoneNumber);
    //formData.append("reflectionUnitId", reflectionProcessingUnitSelected);
      //formData.append("planningUnitId", planningUnitSelected);

      //dataPermissions
      if (!selectedDepartment && !selectedHerdsman && !selectedZionArea && !selectedWMTeam) {  }
      else
      {
          if (selectedDepartment) {
              const depart = {
                  dataType: "DEPARTMENT",
                  value: selectedDepartment.map((item) => { return item.value })
              }
              formData.append("dataPermissions[0].dataType", "DEPARTMENT");
              selectedDepartment.map((item, index) => {
                  return formData.append("dataPermissions[0].value[" + index + "]", item.value);
              })
          }
          else {
              formData.append("dataPermissions[0].dataType", "DEPARTMENT");
              formData.append("dataPermissions[0].value[0]", 0);
          }

          if (selectedHerdsman) {
              formData.append("dataPermissions[1].dataType", "HERDSMAN");
              selectedHerdsman.map((item, index) => {
                  return formData.append("dataPermissions[1].value[" + index + "]", item.value);
              })
          }
          else {
              formData.append("dataPermissions[1].dataType", "HERDSMAN");
              formData.append("dataPermissions[1].value[0]", 0);
          }

          formData.append("dataPermissions[2].dataType", "ZION");
          formData.append("dataPermissions[2].value[0]", 0);
          formData.append("dataPermissions[3].dataType", "GROUP");
          formData.append("dataPermissions[3].value[0]", 0);

          if (selectedZionArea) {
              formData.append("dataPermissions[4].dataType", "ZIONAREA");
              selectedZionArea.map((item, index) => {
                  return formData.append("dataPermissions[4].value[" + index + "]", item.value);
              })
          }
          else {
              formData.append("dataPermissions[4].dataType", "ZIONAREA");
              formData.append("dataPermissions[4].value[0]", 0);
          }

          if (selectedWMTeam) {
              formData.append("dataPermissions[5].dataType", "WMTEAM");
              selectedWMTeam.map((item, index) => {
                  return formData.append("dataPermissions[5].value[" + index + "]", item.value);
              })
          }
          else {
              formData.append("dataPermissions[5].dataType", "WMTEAM");
              formData.append("dataPermissions[5].value[0]", 0);
          }
      }


    userManagementAction
      .UpdateWithMultiRoles(formData)
      .then((result) => {
        if (result) {
          setOrder("desc");
          setOrderBy("modifiedDate");
          GetListUserManagement(1, rowsPerPage);
          onSuccess();
          ShowNotification(
            viVN.Success.UserEditSuccess,
            NotificationMessageType.Success
          );
        }
      })
      .catch((err) => {
        onSuccess();
        ShowNotification(
          err.errorMessage,
          NotificationMessageType.Error
        );
      });
  };

  function handleChangeSelectSex(event) {
    setSex(event.target.value);
    console.log(selected)
  }

  function handleChangeSelect(event) {
    setRoleId(event.target.value);
  }

  const handleDateChange = (date) => {
    setSelectedDate(dateformat(date, "yyyy-mm-dd"));
  };

  const onOpenSelectFile = () => {
    setShow(true);
    setFilesTemp(files);
  };

  const onCloseSelectFile = () => {
    setShow(false);
    setFiles(filesTemp);
  };

  const onSaveSelectFile = () => {
    setShow(false);
  };

  const handleRoleIds = (ids, roleLockup) => {
    let data = []
    if(ids) {
      roleLockup && roleLockup.map((item) => {
        ids.map((id) => {
          if(item.id == id) {
            data.push(item)
          }
        })
      })
    }
    return data.map((item) => {
      return { label: item.name, value: item.id };
    })
  }
  const handleOnChangeRole = (data) => {
    const listData = [];
    if (data) {
      
      data.map((item) => {
        listData.push({value : item.value})
      })
        console.log(listData);
      setSelected(listData)
      setRoleId(listData[0])     
    } else {
      setSelected([]);
    }
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Chỉnh sửa Người dùng</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {userModel && (
            <DialogContent className="pt-4 pb-2">
              <div className="form-group">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <label className="text-dark">
                      Họ và tên<span className="required"></span>
                    </label>
                    <TextField
                      type="text"
                      name="fullName"
                      className="w-100"
                      inputRef={register({ required: true, maxLength: 50 })}
                      defaultValue={userModel.fullName}
                      error={
                        errors.fullName && errors.fullName.type === "required"
                      }
                    />
                    {errors.fullName && errors.fullName.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <label className="text-dark">Giới tính</label>
                    <br />
                    <SelectMui
                      className="w-100"
                      value={sex}
                      onChange={handleChangeSelectSex}
                    >
                      <MenuItem value={true}>Nam</MenuItem>
                      <MenuItem value={false}>Nữ</MenuItem>
                    </SelectMui>
                  </div>
                  
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  
                <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <label className="text-dark">Email</label>
                    <TextField
                      type="text"
                      name="email"
                      className="w-100"
                      defaultValue={userModel.email}
                      inputRef={register}
                      disabled
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <label className="text-dark">Số điện thoại</label>
                    <TextField
                      type="text"
                      name="phoneNumber"
                      defaultValue={userModel.phoneNumber}
                      className="w-100"
                      inputRef={register({
                        pattern: /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
                      })}
                      onChange={(e) =>
                        setValue(
                          "phoneNumber",
                          e.target.value.replace(/[^0-9]/g, "")
                        )
                      }
                    />
                     {errors.phoneNumber && errors.phoneNumber.type === "pattern" && (
                    <span className="error">Số điện thoại không đúng định dạng</span>
                  )}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <label className="text-dark">Ngày sinh</label>
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      locale={viLocale}
                    >
                      <DatePicker
                        id="dateTime"
                        name="startDate"
                        onChange={(date) => date && handleDateChange(date)}
                        format="dd/MM/yyyy"
                        value={selectedDate}
                        fullWidth
                        showTodayButton={true}
                        error={
                          errors.startDate &&
                          errors.startDate.type === "required"
                        }
                      />
                    </MuiPickersUtilsProvider>
                                  </div>
                                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                                      <label className="text-dark">Địa chỉ</label>
                                      <TextField
                                          inputRef={register}
                                          defaultValue={userModel.address}
                                          type="text"
                                          name="address"
                                          className="w-100"
                                      />
                                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  
                  <div className="col-12 col-md-12 col-lg-12 mb-3">
                    <label className="text-dark">Mô tả</label>
                <TextField
                  name="content"
                  type="text"
                  defaultValue={userModel.description}
                  className="w-100"
                  inputRef={register}
                />
                  </div>
                </div>
                          </div>
                          <div className="form-group">
                              <div className="row">
                                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                                      <label className="text-dark">Nhóm quyền</label>
                                      <br />
                                      {
                                          <Select
                                              className="basic-single mb-1"
                                              placeholder="Chọn nhóm quyền"
                                              name="role"
                                              closeMenuOnSelect={true}
                                              defaultValue={userModel.roleIds}
                                              isMulti
                                              onChange={handleOnChangeRole}
                                              options={roleLockup}
                                              noOptionsMessage={() => "không tồn tại"}
                                              menuPortalTarget={document.body}
                                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                          />
                                      }

                                  </div>
                              </div>
                          </div>
                          <div className="form-group">
                              <div className="row">
                                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                                      <label className="text-dark">Quyền địa vực</label>
                                      <Select
                                          className="basic-single mb-1"
                                          placeholder="Chọn địa vực"
                                          name="department"
                                          //closeMenuOnSelect={true}
                                          value={selectedDepartment}
                                          isMulti
                                          onChange={(data) => {
                                              setSelectedDepartment(data);
                                          }}
                                          options={departments}
                                          noOptionsMessage={() => "không tồn tại"}
                                          menuPortalTarget={document.body}
                                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                      />
                                  </div>
                                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                                      <label className="text-dark">Quyền khu vực</label>
                                      <Select
                                          className="basic-single mb-1"
                                          placeholder="Chọn khu vực"
                                          name="zionArea"
                                          //closeMenuOnSelect={true}
                                          value={selectedZionArea}
                                          isMulti
                                          onChange={(data) => {
                                              setSelectedZionArea(data);
                                          }}
                                          options={zionAreas}
                                          noOptionsMessage={() => "không tồn tại"}
                                          menuPortalTarget={document.body}
                                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                      />
                                      
                                  </div>
                              </div>
                          </div>
                          <div className="form-group">
                              <div className="row">
                                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                                      <label className="text-dark">Quyền T.Đồ</label>
                                      <Select
                                          className="basic-single mb-1"
                                          placeholder="Chọn T.Đồ"
                                          name="herdsman"
                                          //closeMenuOnSelect={true}
                                          value={selectedHerdsman}
                                          isMulti
                                          onChange={(data) => {
                                              setSelectedHerdsman(data);
                                          }}
                                          options={herdsmans}
                                          noOptionsMessage={() => "không tồn tại"}
                                          menuPortalTarget={document.body}
                                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                      />
                                  </div>
                                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                                      <label className="text-dark">Quyền WM</label>
                                      <Select
                                          className="basic-single mb-1"
                                          placeholder="Chọn WM"
                                          name="wmteam"
                                          //closeMenuOnSelect={true}
                                          value={selectedWMTeam}
                                          isMulti
                                          onChange={(data) => {
                                              setSelectedWMTeam(data);
                                          }}
                                          options={wmTeams}
                                          noOptionsMessage={() => "không tồn tại"}
                                          menuPortalTarget={document.body}
                                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                      />
                                  </div>
                              </div>
                          </div>
            </DialogContent>
          )}

          <DialogActions className="border-top">
            <Button
              type="button"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {isShow && (
        <Dialog
          onClose={onCloseSelectFile}
          open={isShow}
          fullWidth={true}
          maxWidth="md"
          className="dialog-preview-form"
        >
          <DialogTitle disableTypography>
            <Typography variant="h6">Quản lý file</Typography>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onCloseSelectFile}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <FileManagement
              files={files}
              setFiles={setFiles}
              acceptedFiles={["jpeg", "png", "jpg", "gif"]}
            />
          </DialogContent>

          <DialogActions>
            <Button
              type="button"
              onClick={onCloseSelectFile}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            {files && files.length > 0 && (
              <Button
                type="button"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSaveSelectFile}
              >
                Lưu
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
