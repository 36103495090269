import React, { useEffect, useState } from 'react';
import * as swatTrundoManagementAction from "../../../../redux/store-sarang/swat-trundo/swat-trundo-management.store";
import { NotificationMessageType, formatNumber } from "../../../../utils/configuration";
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import * as viVN from "../../../../language/vi-VN.json";
import LoadingViewAria from '../../../../components/layout-sarang/loading-view-aria'
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
export default function SwatTrundoSumaryWidget() {
    const [dataStatus, setDataStatus] = useState([]);
    const [dataDepartment, setDataDepartment] = useState([])
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState();

    useEffect(() => {
        getSumaryDashboard();
    }, []);

    const getSumaryDashboard = async () => {
        setLoading(true);
        try {
            const res = await swatTrundoManagementAction.GetSumaryDashboard();

            if (res && res.content) {
                setDataStatus(res.content.statusTrundo);
                setDataDepartment(res.content.departmentTrundo);
                setOptions(
                    {
                        chart: {
                            type: 'bar',
                        },
                        title: {
                            text: '',
                        },
                        xAxis: {
                            categories: res.content.departmentTrundo.map((entry) => entry.departmentName),
                        },
                        yAxis: {
                            title: {
                                text: 'Số lượng',
                            },
                            stackLabels: {
                                enabled: true
                            }
                        },
                        plotOptions: {
                            bar: {
                                stacking: 'normal',
                                dataLabels: {
                                    enabled: true
                                }
                            }
                        },
                        series: [{
                            name: 'Ban',
                            data: res.content.departmentTrundo.map((entry) => entry.point)
                        }]
                    }
                );
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="col-12">
                <div className="card">

                    <h5 className="card-header">Tổng hợp</h5>
                    <div className="row card-body">
                        {
                            dataStatus && dataStatus.map((data, index) => (
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12" key={index }>
                                    <div className="card border-3 border-top border-info">
                                        <div className="card-body">
                                            <div className="d-inline-block">
                                                <h5 className="text-muted"><span style={{ cursor: 'Pointer' }}>{data.statusName}</span></h5>
                                                <h2 className="mb-0"> {formatNumber(data.quantity)} <small>({formatNumber(data.point)})</small></h2>
                                            </div>
                                            <div className="float-right icon-circle-small  icon-box-md  bg-info-light mt-1">
                                                <i className="fas fa-hands-helping fa-fw fa-sm text-info"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>

                    <LoadingViewAria loading={loading} />
                </div>
            </div>
            {
                //<div className="col-xl-3 col-lg-3 col-md-3 col-12">
                //    <div className="card">
                //        <h5 className="card-header">Tổng hợp theo ban</h5>
                //        <div className="card-body">
                //            <HighchartsReact highcharts={Highcharts} options={options} />
                //        </div>

                //        <LoadingViewAria loading={loading} />
                //    </div>
                //</div>
            }
        </>   
    )
}