import React, { useEffect, useState } from 'react';
import LoadingScreen from '../loading-with-queue/loading-with-queue';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeaderSarang from './header/header';
import SideBarSarang from './side-bar/side-bar';
import FooterSarang from './footer/footer';
import PageTitleSarang from './page-title/page-title';
import { DomainAdminSide, TokenKey, getUserInfo, removeCookies, setCookies, setCookiesUser, NotificationMessageType } from '../../utils/configuration';
import * as accAction from "../../redux/store/account/account.store";
import ReactGA from "react-ga4";

import { useLaunchParams, isTMA, retrieveLaunchParams, mockTelegramEnv, parseInitData } from '@telegram-apps/sdk-react';
import * as accountAction from "../../redux/store/account/account.store";
import { GenerateDeviceId } from "../../common/tools";
import { login } from "../../common/login";

import { ConnectTelegramWarning } from "../../components/react-notifications/react-notifications";
import * as viVN from "../../language/vi-VN.json";
function LayoutSarangView (props: any) {
  const { title, breadcrumbList, breadcrumbActive } = props;
  const [isLogin, setIsLogin] = useState(getUserInfo() ? true : false);
  const [user, setUser] = useState(getUserInfo());
  const [screenAllow, setScreenAllow] = useState([]);

  //const { initDataRaw } = useLaunchParams();
    //const initDataRaw ="user=%7B%22id%22%3A279058397%2C%22first_name%22%3A%22Vladislav%22%2C%22last_name%22%3A%22Kibenko%22%2C%22username%22%3A%22vdkfrost%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=-3788475317572404878&chat_type=private&auth_date=1709144340&hash=371697738012ebd26a111ace4aff23ee265596cd64026c8c3677956a85ca1827"
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: title });
    }, []);

  function GetScreenAllow() {
    accAction.GetScreenAllow().then(res => {
      // setScreenAllow(modules.filter(item => {
      //   if (res.content.some(x => x.code === item.code))
      //     return item
      // }))
    }).catch(error => console.log(error))
  }

  useEffect(() => {
    if( isLogin ) {
        //GetScreenAllow();
        //if ((!user || !user?.chatTelegramId) && user?.email != "sarang@gmail.com")
        if (user && !user.chatTelegramId && user.herdsmanId) {
            console.log("user", user)
            ConnectTelegramWarning(
                "ddd",
                NotificationMessageType.Warning
            );
        }
    }
    if (isLogin && user && user.userRole) {
    } else {
        login();
    }
  })

  return (
    <div className='dashboard-main-wrapper'>
      <LoadingScreen />
      <HeaderSarang />
      <SideBarSarang />

      <div className="dashboard-wrapper">
        <div className="container-fluid dashboard-content">
          <PageTitleSarang
            title={title}
            breadcrumbList={breadcrumbList}
            breadcrumbActive={breadcrumbActive}
          />
                  {props.children}
              </div>
              {/*{props.children}*/}
              <FooterSarang isShowFooter={ true} />
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  isCollapsed: state.app.isCollapsed,
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LayoutSarangView);
