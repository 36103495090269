import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import Select from "react-select";
import { patternNumber } from '../../../common/validatePhoneNumber';
import { optionsCuisineOrderStatus, optionsReceiptTime } from '../../../common/constant';
import * as communeManagementAction from "../../../redux/store/commune-management/commune.store"
import { getUserInfo } from '../../../utils/configuration';

export default function FormAddEditCuisineOrder(props) {
    const now = new Date();
    const isNoCookingDate = (date) => {
        const day = date.day();
        const dateNumber = date.date();
        return day === 2 || day === 4 || day === 6; //|| dateNumber === 19 || dateNumber === 20;
    };
    
    const { triggerSubmit, setTriggerSubmit, onCloseModal, updateItem, 
        district, herdsman, setting,
        onSubmitAddEdit } = props;
    const buttonSubmitRef = useRef(null);

    const [optionsHerdsman, setOptionsHerdsman] = useState(herdsman.map(item => { return { label: item.name, value: item.id } }));
    const [currentLoggedInHerdsman] = useState({ label: getUserInfo()?.fullName, value: getUserInfo()?.herdsmanId });
    const [deliveryType, setDeliveryType] = useState(updateItem?.deliveryType?? 0);

    useEffect(() => {
        const autoGenOrderCode = `ORDER${dayjs(now).valueOf()}`;
        setValue("orderCode", autoGenOrderCode);

        //setValue("herdsManOrderId", updateItem?.herdsManOrderId);
        setValue("herdsManOrderId", updateItem?.herdsManOrderId ? optionsHerdsman.find(item => item.value == updateItem?.herdsManOrderId)?.value : (currentLoggedInHerdsman?.value ? optionsHerdsman.find(item => item.value == currentLoggedInHerdsman.value)?.value : null));
        setValue("address", updateItem?.address);
        setValue("communeId", updateItem?.communeId);
        setValue("districtId", updateItem?.districtId);
        setValue("provinceId", updateItem?.provinceId);
        setValue("quatity", updateItem?.quatity);
        setValue("receiptTime", updateItem?.receiptTime ? updateItem?.receiptTime : 2);
        // setValue("orderStatus", updateItem?.orderStatus || 10);
        setValue("description", updateItem?.description);
        setValue("orderDate", updateItem?.orderDate || dayjs(now).format());
        if ( !updateItem ) return;
        setValue("orderCode", updateItem?.orderCode);
        getDetailCommune(updateItem?.communeId);
    }, [])

    const { register, handleSubmit, errors, setValue, setError } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const onSubmit = (data) => {
        if (!data) {
          return;
        }
        
        const dataInput = {...data, id: updateItem?.id};
        delete dataInput.districtId;
        dataInput.quatity = parseInt(dataInput.quatity, 10);
        dataInput.orderStatus = updateItem?.orderStatus || 10;
        dataInput.isActived = updateItem?.isActived || true;

        onSubmitAddEdit(dataInput);
        onCloseModal();
    }

    useEffect(()=>{
        if (!triggerSubmit) return;
        buttonSubmitRef.current.click();
        setTriggerSubmit(false);
    }, [triggerSubmit])

    const [commune, setCommune] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState(null);

    const getLookupCommune = async (districtId) => {
        try {
            const res = await communeManagementAction.GetLookupCommune(districtId);
            if (res && res.content) {
                setCommune(res.content);
            }
        } catch (err) {
            throw err;
        }
    };

    const getDetailCommune = async (communeId) => {
        try {
            const res = await communeManagementAction.GetDetailCommune(communeId);
            if (res && res.content) {
                setSelectedDistrict({label: res.content.districtName, value: res.content.districtId});
                setValue("districtId", res.content.districtId);
            }
        } catch (err) {
            throw err;
        }
    };

    useEffect(() => {
        if( !selectedDistrict ) return;
        getLookupCommune(selectedDistrict.value)
    }, [selectedDistrict])

    const onChangeDateValue = (data) => {
        setValue("orderDate", dayjs(data).format());
        setError("orderDate", {
            type: "manual",
            message: "Sai ngày tháng rồi!",
        })
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="text-center row">
                <div className="col-12"><span className='required'>(ACE giúp đỡ đặt suất ăn trước {setting.cuisineOrderHour}:{setting.cuisineOrderMinute < 10 ? "0" + setting.cuisineOrderMinute : setting.cuisineOrderMinute} sáng cùng ngày. TKAO!)</span></div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Mã đăng ký</label>
                    <input
                        className="form-control"
                        type="text"
                        name="orderCode"
                        placeholder="Nhập Địa chỉ"
                        ref={register()}
                        readOnly
                    />
                </div>
                <div className="form-group col-md-6">
                    <label>Người nhận <span className='required'>*</span></label>
                    <Select
                        isClearable
                        {...register("herdsManOrderId", { required: true })}
                        //defaultValue={updateItem?.herdsManOrderId ? { label: updateItem?.herdsManOrderName, value: updateItem?.herdsManOrderId } : null}
                        //defaultValue={updateItem?.herdsmanId ? { label: updateItem?.herdsmanName, value: updateItem?.herdsmanId } : (currentLoggedInHerdsman ? currentLoggedInHerdsman : null)}
                        defaultValue={updateItem?.herdsManOrderId ? optionsHerdsman.find(item => item.value == updateItem?.herdsManOrderId) : (currentLoggedInHerdsman ? optionsHerdsman.find(item => item.value == currentLoggedInHerdsman.value) : null)}
                        placeholder="Chọn Người nhận"
                        closeMenuOnSelect={true}
                        onChange={(data) => setValue("herdsManOrderId", data ? data.value : null)}
                        options={optionsHerdsman}
                        noOptionsMessage={() => "Không tồn tại"}
                    />
                    {errors.herdsManOrderId && errors.herdsManOrderId.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Ngày nhận <span className='required'>*</span></label>
                    <DatePicker
                        {...register("orderDate", {
                            required: true,
                            validate: (value) => {
                                var datetime = new Date();
                                var today = new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate());
                                var dateValue = new Date(value);
                                dateValue = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate());
                                
                                if (dateValue < today)
                                    return "Ngày nhận phải bằng hoặc lớn hơn ngày hiện tại";

                                //if (dateValue < today || (dateValue - today === 0 && datetime.getHours() > 10))
                                if (!updateItem?.id && (dateValue < today || (dateValue - today === 0 && (datetime.getHours() > setting.cuisineOrderHour || (datetime.getHours() == setting.cuisineOrderHour && datetime.getMinutes() > setting.cuisineOrderMinute)))))
                                    return "Chỉ được đặt suất ăn trước " + setting.cuisineOrderHour + ":" + (setting.cuisineOrderMinute < 10 ? "0" + setting.cuisineOrderMinute : setting.cuisineOrderMinute) + " sáng cùng ngày";
                                var day = dateValue.getDay();
                                var dateNumber = dateValue.getDate();
                                if (day === 2 || day === 4 || day === 6)  // || dateNumber === 24 || dateNumber === 25
                                    return "Nhà hàng không hoạt động vào ngày này";
                                return true;
                            }
                        })}
                        shouldDisableDate={isNoCookingDate}
                        disablePast
                        onChange={(data) => {
                            if (data)
                                setValue("orderDate", dayjs(data).format());
                            else
                                setValue("orderDate", undefined);
                        }}
                        defaultValue={dayjs(updateItem?.orderDate)}
                        className="form-control"
                        slotProps={{ textField: { size: 'small' } }}
                        format='DD/MM/YYYY'
                    />
                    {errors.orderDate && errors.orderDate.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                    {errors.orderDate && errors.orderDate.type === "validate" && (
                        <span className="error">{errors.orderDate.message}</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label>Thời gian nhận <span className='required'>*</span></label>
                    <Select
                        {...register("receiptTime", {
                            required: true
                        })}
                        defaultValue={optionsReceiptTime.find(item => item.value === updateItem?.receiptTime) || {value: 2,label: "Trưa"}}
                        placeholder="Thời gian nhận"
                        closeMenuOnSelect={true}
                        onChange={(data) => setValue("receiptTime", data ? data.value : null)}
                        options={optionsReceiptTime}
                        noOptionsMessage={() => "Không tồn tại"}
                    />
                    {errors.receiptTime && errors.receiptTime.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}

                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Số lượng <span className='required'>*</span></label>
                    <input
                        className="form-control"
                        type="text"
                        name="quatity"
                        defaultValue={updateItem?.quatity || null}
                        placeholder="Nhập Số lượng"
                        ref={register({
                            required: true,
                            validate: (value) => {
                                if (parseInt(value) <= 0) {
                                    return "invalidQuantity";
                                }
                                return true;
                            }
                        })}
                        onChange={(e) =>
                            setValue(
                                "quatity",
                                e.target.value.replace(patternNumber, "")
                            )
                        }
                    />
                    {errors.quatity && errors.quatity.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                    )}
                    {errors.quatity && errors.quatity.type === "validate" && (
                        <span className="error">Giá trị không hợp lệ</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label>Hình thức giao</label>
                    <select className="form-control" name="deliveryType" ref={register()} defaultValue={updateItem?.deliveryType}
                        onChange={(e) => {
                            setDeliveryType(e.target.value);
                        }}
                    >
                        <option value="0">Giao nhận</option>
                        <option value="1">Tự lấy</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Quận/ huyện {deliveryType==0 ? <span className='required'>*</span> : ""}</label>
                    <Select
                        isClearable
                        {...register("districtId", { required: deliveryType==0 })}
                        // defaultValue={updateItem?.districtId ? {label: updateItem?.districtName, value: updateItem?.districtId} : null}
                        value={selectedDistrict}
                        placeholder="Chọn Quận/ huyện"
                        closeMenuOnSelect={true}
                        onChange={(data) => {
                            setValue("districtId", data ? data.value : null);
                            setSelectedDistrict(data || null);
                        }}
                        options={district.map(item => { return { label: item.name, value: item.id } })}
                        noOptionsMessage={() => "Không tồn tại"}
                        isDisabled={deliveryType==1}
                    />
                    {deliveryType ==0 && errors.districtId && errors.districtId.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label>Phường/ Xã {deliveryType == 0 ? <span className='required'>*</span> : ""}</label>
                    <Select
                        isDisabled={commune.length == 0 || deliveryType == 1}
                        isClearable
                        // {...register("communeId", { required: true })}
                        {...register("communeId", { required: deliveryType == 0 })}
                        defaultValue={updateItem?.communeId ? {label: updateItem?.communeName, value: updateItem?.communeId} : null}
                        placeholder="Chọn Phường/ Xã"
                        closeMenuOnSelect={true}
                        onChange={(data) => setValue("communeId", data ? data.value : null)}
                        options={commune.map(item => {return {label: item.name, value: item.id}})}
                        noOptionsMessage={() => "Không tồn tại"}
                    />
                    {deliveryType == 0  && errors.communeId && errors.communeId.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label>Địa chỉ {deliveryType == 0 ? <span className='required'>*</span> : ""}</label>
                    <input
                        className="form-control"
                        type="text"
                        name="address"
                        defaultValue={updateItem?.address}
                        placeholder="Nhập Địa chỉ"
                        ref={register({ required: deliveryType == 0 })}
                        readOnly={deliveryType == 1 }
                    />
                    {deliveryType == 0 && errors.address && errors.address.type === "required" && (
                        <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                <div className="form-group col-md-6">
                    <label>Mô tả</label>
                    <input
                        className="form-control"
                        type="text"
                        name="description"
                        defaultValue={updateItem?.description}
                        placeholder="Nhập Mô tả"
                        ref={register()}
                    />
                </div>
            </div>
            
            <button ref={buttonSubmitRef} style={{display: "none"}} type="submit" className="btn btn-primary">
                    Lưu
            </button>
        </form>
    )
}