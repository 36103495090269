import React from 'react';
import { useHistory } from 'react-router-dom';


import { useMediaQuery } from 'react-responsive';

import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SearchIcon from '@material-ui/icons/Search';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HomeIcon from '@material-ui/icons/Home';
import FastfoodIcon from '@material-ui/icons/Fastfood';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
});

export default function FooterSarang(props) {
    const { openAddDialog, isShowFooter, openSearch, setOpenSearch, } = props;
    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

    const classes = useStyles();
    const [navigationValue, setNavigationValue] = React.useState(0);
    const history = useHistory();

    const handleChangeButton = (event, newValue) => {
        setNavigationValue(newValue);
        if (newValue === 2)
            openAddDialog();
        else if (newValue === 1)
            setOpenSearch(openSearch => !openSearch);
        else if (newValue === 0)
            history.push('/');
        else if (newValue === 3)
            history.push('/hoat-dong-am-thuc');
    }
    return (
        <div className="footer footer-absolute">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        Copyright © 2024 HoaNi. All rights reserved.
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                        <div className="text-md-right footer-links d-none d-sm-block">
                            {/*<a href="/huong-dan">Hướng dẫn</a>*/}
                            {/*<a href="#">Sự cố</a>*/}
                            {/*<a href="#">Contact Us</a>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        //<>
        //    {
        //        isDesktopOrLaptop || isShowFooter ?
        //            <div className="footer footer-absolute">
        //            <div className="container-fluid">
        //                <div className="row">
        //                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        //                        Copyright © 2024 HoaNi. All rights reserved.
        //                    </div>
        //                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
        //                        <div className="text-md-right footer-links d-none d-sm-block">
        //                            {/*<a href="/huong-dan">Hướng dẫn</a>*/}
        //                            {/*<a href="#">Sự cố</a>*/}
        //                            {/*<a href="#">Contact Us</a>*/}
        //                        </div>
        //                    </div>
        //                </div>
        //            </div>
        //        </div>
        //            : <></>
        //    }
        //    {
        //        isTabletOrMobile ? 
        //            <div className="footer position-sticky">
        //                <div className="container-fluid1">
        //                    <BottomNavigation
        //                        value={navigationValue}
        //                        onChange={(event, newValue) => {
        //                            handleChangeButton(event, newValue);
        //                        }}
        //                        showLabels
        //                        className={`position-sticky ${classes.root}`}
        //                    >
        //                        <BottomNavigationAction label="Trang chủ" icon={<HomeIcon />} />
        //                        <BottomNavigationAction label="Tìm kiếm" icon={<SearchIcon />} />
        //                        <BottomNavigationAction label="Thêm mới" icon={<AddCircleIcon />} />
        //                        <BottomNavigationAction label="Đặt cơm" icon={<FastfoodIcon />} />
        //                    </BottomNavigation>
        //                </div>
        //            </div>
        //        : <></>
        //    }
        //</>
    )
}