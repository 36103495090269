import React from 'react';
import { permission } from "../../../common/constant";

export function BodyDesktop(props) {
    const { data, isShowActionButton, userInfo, setOpenSendMessage, openSendMessageDialog,
        setOpenDeactiveDialog, openConfirmDialog, setOpenActiveDialog,
        setOpenCreateAccountDialog, updatePermission, openEditDialog, setOpenDeleteDialog    } = props;

    return (
        <tbody>
            {
                data.length > 0 ?
                    data.map((row, rowIndex) => (
                        <tr key={rowIndex} style={row.isCaptain ? { backgroundColor: 'lightgrey' } : {}}>
                            <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}
                            ><span>{row["gender"] ? <li className="fas fa-male text-primary"></li> : <li className="fas fa-female text-secondary"></li>} {`${row["firstName"]} ${row["lastName"]}`}</span>
                                {userInfo && userInfo.userRoles && (userInfo.userRoles.includes(permission.Chapsu) || userInfo.userRoles.includes(permission.Admin)) && row.chatTelegramId &&
                                    <span>&nbsp;<i className="fab fa-telegram-plane mr-2 text-info" title="Gửi tin nhắn" style={{ cursor: 'pointer' }} onClick={() => {
                                        setOpenSendMessage(true);
                                        openSendMessageDialog(row);
                                    }}></i></span>}
                            </td>
                            <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}><span>{row.departmentName}</span></td>
                            <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}><span> {row.zionAreaName}</span></td>
                            <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}><span> {row.wmTeamName}</span></td>
                            <td><span>{row["description"]}</span></td>
                            <td className={row.isCaptain ? "text-dark" : ""} style={row.isCaptain ? { fontWeight: 700 } : {}}><span> {row.phoneNumber}</span></td>
                            <td>
                                <span>
                                    {row.isActived ? (
                                        <img title="Đang kích hoạt" style={{ cursor: 'pointer' }}
                                            src={require("../../../assets/icon/tick.png")}
                                            alt="Tick" onClick={() => {
                                                setOpenDeactiveDialog(true);
                                                openConfirmDialog(row);
                                            }}
                                        />
                                    ) : (
                                        <img title="Ngưng kích hoạt" style={{ cursor: 'pointer' }}
                                            src={require("../../../assets/icon/cancel.png")}
                                            alt="Cancel" onClick={() => {
                                                setOpenActiveDialog(true);
                                                openConfirmDialog(row);
                                            }}
                                        />
                                    )}
                                </span>
                            </td>
                            <td>
                                {
                                    isShowActionButton && <div className='d-flex'>
                                        {
                                            userInfo && userInfo.userRoles && (
                                                userInfo.userRoles.includes(permission.Chapsu) ||
                                                userInfo.userRoles.includes(permission.Admin) ||
                                                userInfo.userRoles.includes(permission.Diavuctruong) ||
                                                userInfo.userRoles.includes(permission.Dvt_hn)
                                            ) && !row.userId && row.positionId === 5 &&
                                            <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table create-account"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Tạo tài khoản"
                                                onClick={() => {
                                                    setOpenCreateAccountDialog(true);
                                                    openConfirmDialog(row);
                                                }}
                                            >
                                                <i className=" far fa-user"></i>
                                            </button>
                                        }
                                        {
                                            userInfo && userInfo.userRoles && (userInfo.userRoles.includes(permission.Admin)) && row.userId && row.positionId === 5 &&
                                            <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table create-account"
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="Thêm quyền KVT"
                                                onClick={() => {
                                                    updatePermission(row.id);
                                                }}
                                            >
                                                <i className=" far fa-user"></i>
                                            </button>
                                        }
                                        <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                            title="Sửa"
                                            onClick={() => { openEditDialog(row) }}
                                        >
                                            <i className="far fa-edit"></i>
                                        </button>
                                        {userInfo && userInfo.userRoles && (userInfo.userRoles.includes(permission.Chapsu) || userInfo.userRoles.includes(permission.Admin)) &&
                                            <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table delete" data-toggle="tooltip" data-placement="top"
                                                title="Xóa"
                                                onClick={() => {
                                                    setOpenDeleteDialog(true);
                                                    openConfirmDialog(row);
                                                }}
                                            >
                                                <i className="far fa-trash-alt"></i>
                                            </button>
                                        }
                                    </div>
                                }
                            </td>
                        </tr>
                    )) :
                    <tr><td colSpan={10}>Chưa có dữ liệu</td></tr>
            }
        </tbody>
    )
}

export function BodyMobile(props) {
    const { data, isShowActionButton, userInfo, setOpenSendMessage, openSendMessageDialog,
        setOpenDeactiveDialog, openConfirmDialog, setOpenActiveDialog,
        setOpenCreateAccountDialog, updatePermission, openEditDialog, setOpenDeleteDialog } = props;

    return (
        data.length > 0 ?
            data.map((item, index) => (
                <div key={index} className="card">
                    <div className="d-flex align-items-center" id={`heading-${item.id}`}>
                        <div className="list-group-item list-group-item-action flex-column align-items-start collapsed" style={{ border: "none", backgroundColor: item.isCaptain ? 'lightgrey' : '' }}
                            data-toggle="collapse" data-target={`#collapse-${item.id}`} aria-expanded="false" aria-controls={`collapse-${item.id}`}>
                            <div className="d-flex w-100 justify-content-between">
                                <h5 className="mb-1">{`${item["firstName"]} ${item["lastName"]}`}</h5>
                                {
                                    isShowActionButton && 
                                    <small className='d-flex align-items-center'>
                                        <div className='d-flex'>
                                            {
                                                userInfo && userInfo.userRoles && (
                                                    userInfo.userRoles.includes(permission.Chapsu) ||
                                                    userInfo.userRoles.includes(permission.Admin) ||
                                                    userInfo.userRoles.includes(permission.Diavuctruong) ||
                                                    userInfo.userRoles.includes(permission.Dvt_hn)
                                                ) && !item.userId && item.positionId === 5 &&
                                                <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table create-account"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Tạo tài khoản"
                                                    onClick={() => {
                                                        setOpenCreateAccountDialog(true);
                                                        openConfirmDialog(item);
                                                    }}
                                                >
                                                    <i className=" far fa-user"></i>
                                                </button>
                                            }
                                            {
                                                userInfo && userInfo.userRoles && (userInfo.userRoles.includes(permission.Admin)) && item.userId && item.positionId === 5 &&
                                                <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table create-account"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Thêm quyền KVT"
                                                    onClick={() => {
                                                        updatePermission(item.id);
                                                    }}
                                                >
                                                    <i className=" far fa-user"></i>
                                                </button>
                                            }
                                            <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                                title="Sửa"
                                                onClick={() => { openEditDialog(item) }}
                                            >
                                                <i className="far fa-edit"></i>
                                            </button>
                                            {userInfo && userInfo.userRoles && (userInfo.userRoles.includes(permission.Chapsu) || userInfo.userRoles.includes(permission.Admin)) &&
                                                <button className="d-inline-block btn btn-sm btn-outline-light custom-button-table delete" data-toggle="tooltip" data-placement="top"
                                                    title="Xóa"
                                                    onClick={() => {
                                                        setOpenDeleteDialog(true);
                                                        openConfirmDialog(item);
                                                    }}
                                                >
                                                    <i className="far fa-trash-alt"></i>
                                                </button>
                                            }
                                            {userInfo && userInfo.userRoles && (userInfo.userRoles.includes(permission.Chapsu) || userInfo.userRoles.includes(permission.Admin)) && item.chatTelegramId &&
                                                <button className="d-inline-block btn btn-sm btn-info custom-button-table" data-toggle="tooltip" data-placement="top"
                                                    title="Gửi tin nhắn"
                                                    onClick={() => {
                                                        setOpenSendMessage(true);
                                                        openSendMessageDialog(item);
                                                    }}
                                                >
                                                    <i className="fab fa-telegram-plane mr-2"></i>
                                                </button>}
                                        </div>
                                    </small>
                                }
                                
                            </div>
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='mt-0'><b>Địa vực:</b></div>
                                    <span>{item.departmentName}</span><br />
                                    <div className='mt-0'><b>Khu vực:&nbsp;</b>
                                        <span>{item.zionAreaName}</span>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='mt-0'><b>WM:&nbsp;</b>
                                        <span>{item.wmTeamName}</span>
                                    </div>
                                    <div className='mt-0'><b>ĐT:&nbsp;</b>
                                        <span>{item.phoneNumber}</span>
                                    </div>
                                    <div className='mt-0'>
                                        <b>Hoạt động: </b>
                                        <span>
                                            {item.isActived ? (
                                                <img title="Đang kích hoạt" style={{ cursor: 'pointer' }}
                                                    src={require("../../../assets/icon/tick.png")}
                                                    alt="Tick" onClick={() => {
                                                        setOpenDeactiveDialog(true);
                                                        openConfirmDialog(item);
                                                    }}
                                                />
                                            ) : (
                                                <img title="Ngưng kích hoạt" style={{ cursor: 'pointer' }}
                                                    src={require("../../../assets/icon/cancel.png")}
                                                    alt="Cancel" onClick={() => {
                                                        setOpenActiveDialog(true);
                                                        openConfirmDialog(item);
                                                    }}
                                                />
                                            )}
                                        </span>
                                    </div>
                                    <div className='mt-0'>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            )) : <div className="card">Chưa có dữ liệu</div>
    )
}