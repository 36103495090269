import React, { useEffect, useState } from 'react';
import * as examinationAction from "../../../redux/store-sarang/examination-report/examination-report-management.store";
import { NotificationMessageType, getFestivalCog } from "../../../utils/configuration";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../language/vi-VN.json";
import LoadingViewAria from '../../../components/layout-sarang/loading-view-aria'
import { optionsExamination } from '../../../common/constant';
import Popover from '@mui/material/Popover';
export default function HerdsmanRankCupWidget(props) {
    const { week, limit } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [loading, setLoading] = useState(false);
    const [listHerdsmanRank, setListHerdsmanRank] = useState([]);
    const [herdsmanData, setHerdsmanData] = useState();
    const currentFestival = getFestivalCog();

    useEffect(() => {
        GetAllListHerdsmanRank()
    }, [week]);

    const GetAllListHerdsmanRank = async () => {
        setLoading(true);
        try {
            const res = await examinationAction.GetAllListHerdsmanRank(currentFestival?.id, week?.week);

            if (res && res.content) {
                setListHerdsmanRank(res.content);
            }

        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoading(false);
        }
    };

    const GetHerdsmanExaminationReport = async (herdsmanId) => {
        setLoading(true);
        try {
            const res = await examinationAction.GetHerdsmanExaminationReport(herdsmanId, currentFestival?.id, week?.week);

            if (res && res.content) {
                setHerdsmanData(res.content);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            setLoading(false);
        }
    };

    const herdsmanClick = (event, hdmId) => {
        setAnchorEl(event.currentTarget);
        GetHerdsmanExaminationReport(hdmId);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {
                <Popover
                    id='buoc1'
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {/*<Typography sx={{ p: 2 }}>test</Typography>*/}
                    {
                        herdsmanData?.rP_Examination_Report_Dtos && herdsmanData?.rP_Examination_Report_Dtos.length > 0 &&
                        <table className="table dataTable">
                            <thead>
                                <tr >
                                    <td style={{ minWidth: "100px" }}><b>Nội dung</b></td>
                                    <td style={{ minWidth: "50px" }}><b>#</b></td>
                                    <td style={{ minWidth: "50px" }}><b>Điểm</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    herdsmanData?.rP_Examination_Report_Dtos.map((itemherdsman, index) =>
                                        <tr>
                                            <td>{optionsExamination.find(x => x.value == itemherdsman.status)?.label}</td>
                                            <td>{itemherdsman.quantity}</td>
                                            {/*<td>{Math.round((itemherdsman.quantity * itemherdsman.point) * 100) / 100}</td>*/}
                                            <td>{Math.round((itemherdsman.point) * 100) / 100}</td>
                                        </tr>)
                                }
                            </tbody>
                        </table>
                    }
                </Popover>
            }
            <div className="card">
                <h5 className="card-header">SWAT ưu tú ({week?.week ? "tuần " + week?.week : "tháng " + week?.month })</h5>
                <div className="card-body">
                    <div className="row text-center">
                        <div className="col-4">
                            {
                                listHerdsmanRank.length > 1 &&
                                <div style={{ cursor: 'pointer' }} onClick={(event) => {
                                    herdsmanClick(event, listHerdsmanRank[1].herdsmanId);
                                }}>
                                    <img src="assets/images/rank/person_top2.jpg" style={{width: "40px", paddingTop:"10px"}} />
                                        <div style={{ marginTop: "10px", fontWeight: 700}} >{
                                        listHerdsmanRank[1].herdsmanName + " (" + listHerdsmanRank[1].departmentName + ")"
                                    }</div>
                                    <div style={{ fontWeight: 900, color:"#0867c3" }}>{
                                        listHerdsmanRank[1].totalPoint
                                    }</div>
                                </div>
                            }
                        </div>
                        <div className="col-4">
                            {
                                
                                listHerdsmanRank.length > 0 &&
                                <div style={{ cursor: 'pointer' }} onClick={(event) => {
                                    herdsmanClick(event, listHerdsmanRank[0].herdsmanId);
                                }}>
                                    <img src="assets/images/rank/person_top1.jpg" style={{width: "50px"}}  />
                                        <div style={{ marginTop: "10px", fontWeight: 700}} >{
                                        listHerdsmanRank[0].herdsmanName + " (" + listHerdsmanRank[0].departmentName + ")"
                                    }</div>
                                        <div style={{ fontWeight: 900, color: "#0867c3" }}>{
                                        listHerdsmanRank[0].totalPoint
                                    }</div>
                                </div>
                            }
                        </div>
                        <div className="col-4">
                            {
                                listHerdsmanRank.length > 2 &&
                                <div style={{ cursor: 'pointer' }} onClick={(event) => {
                                    herdsmanClick(event, listHerdsmanRank[2].herdsmanId);
                                }}>
                                    <img src="assets/images/rank/person_top3.jpg" style={{width: "35px", paddingTop:"15px"}}  />
                                        <div style={{ marginTop: "10px", fontWeight: 700}} >{
                                        listHerdsmanRank[2].herdsmanName + " (" + listHerdsmanRank[2].departmentName + ")"
                                    }</div>
                                        <div style={{ fontWeight: 900, color: "#0867c3" }}>{
                                        listHerdsmanRank[2].totalPoint
                                    }</div>
                                </div>
                            }
                        </div>
                        
                    </div>
                    <div className="row text-center">
                        <table className="table dataTable table-hover " id="datatable">
                            <thead>
                                <tr>
                                    <td style={{ minWidth: "30px" }}><b>Hạng</b></td>
                                    <td style={{ minWidth: "100px" }}><b>Swat</b></td>
                                    <td style={{ minWidth: "70px" }}><b>Đội</b></td>
                                    <td style={{ minWidth: "30px" }}><b>Điểm</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listHerdsmanRank.map((item, index) => 
                                        index > 2 && index <=(limit ? limit -1: 9) &&
                                            <tr>
                                                <td>{item.rank}</td>
                                                <td style={{ cursor: 'pointer' }} onClick={(event) => {
                                                    herdsmanClick(event, item.herdsmanId);
                                        }}>{item.herdsmanName}</td>
                                                <td>{item.departmentName}</td>
                                                <td>{item.totalPoint}</td>
                                            </tr>
                                    
                                        )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <LoadingViewAria loading={loading} />
            </div>
        </>
    )
}