const headCellsListHerdsman = [
    {
        id: 'Name',
        numeric: false,
        disablePadding: false,
        label: 'Họ và tên',
        className: 'pt-3 pb-3',
        style: { minWidth: "130px" }
    },
    {
        id: 'DepartmentId',
        numeric: false,
        disablePadding: false,
        label: 'Địa vực',
        className: 'pt-3 pb-3',
        style: { minWidth: "100px" }
    },
    {
        id: 'ZionAreaId',
        numeric: false,
        disablePadding: false,
        label: 'Khu vực',
        className: 'pt-3 pb-3',
        style: { minWidth: "50px" }
    },
    {
        id: 'WMTeamId',
        numeric: false,
        disablePadding: false,
        label: 'WM',
        className: 'pt-3 pb-3',
        style: { minWidth: "80px" }
    },
    {
        id: 'Description',
        numeric: false,
        disablePadding: false,
        label: 'Mô tả',
        className: 'pt-3 pb-3',
        style: { minWidth: "120px" }
    },
    {
        id: 'phoneNumber',
        numeric: false,
        disablePadding: false,
        label: 'Số ĐT',
        className: 'pt-3 pb-3',
        style: { minWidth: "80px" }
    },
    {
        id: 'IsActived',
        numeric: false,
        disablePadding: false,
        label: 'Hoạt động',
        className: 'pt-3 pb-3',
    },
    //{
    //    id: 'PersonManageId',
    //    numeric: false,
    //    disablePadding: false,
    //    label: 'Người quản lý',
    //    className: 'pt-3 pb-3',
    //},
    {
        id: 'action',
        numeric: false,
        disablePadding: true,
        label: '',
        className: 'pt-3 pb-3 pl-4',
    },
];

export default headCellsListHerdsman;